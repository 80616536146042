<template>
  <div class="feedback">
    <form @submit.prevent="submitForm">
    <b-container class="whiteBackground roundedBackground py-5">

      <div class="name-address-comp px-3">
        <div class="NA-title w-10 pb-4">
          <p
              v-for="(paragraph, index) in data.text"
              :key="`${$route.name}_paragraphs_${index}`"
          >
            {{ paragraph }}
          </p>
          <p class="mb-0"
             v-for="(paragraph, index) in data.textAddress"
             :key="`${$route.name}_paragraphs_${index}`"
          >
            {{ paragraph }}
          </p>
        </div>

        <!-- name-->
        <b-row>
          <b-col cols="12" lg="6" class="NA-input">
            <div class="NA-input-label">{{ data.nameAddress.fname.label }}</div>
            <div>
              <input
                  required
                  type="text"
                  :placeholder="data.nameAddress.fname.placeholder"
                  v-model="nameAddressData.firstName"
              />
            </div>
          </b-col>
        </b-row>

        <!-- last name-->
        <b-row>
          <b-col cols="12" lg="6" class="NA-input">
          <div class="NA-input-label">{{ data.nameAddress.lname.label }}</div>
          <div>
            <input
                required
                type="text"
                :placeholder="data.nameAddress.lname.placeholder"
                v-model="nameAddressData.lastName"
            />
          </div>
        </b-col>
        </b-row>

        <!-- number -->
        <b-row>
          <b-col cols="12" lg="6" class="NA-input">
            <div class="NA-input-label">{{ data.nameAddress.number.label }}</div>
            <div>
              <input
                  type="text"
                  :placeholder="data.nameAddress.number.placeholder"
                  v-model="nameAddressData.number"
              />
            </div>
          </b-col>
        </b-row>

        <!-- email -->
        <b-row>
          <b-col cols="12" lg="6" class="NA-input">
            <div class="NA-input-label">{{ data.nameAddress.email.label }}</div>
            <div>
              <input
                  required
                  type="email"
                  :placeholder="data.nameAddress.email.placeholder"
                  v-model="nameAddressData.emailAddress"
              />
            </div>
          </b-col>
        </b-row>

        <!-- address 1-->
        <b-row>
          <b-col cols="12" lg="6" class="NA-input">
            <div class="NA-input-label">{{ data.nameAddress.address1.label }}</div>
            <div>
              <input
                  type="text"
                  :placeholder="data.nameAddress.address1.placeholder"
                  v-model="nameAddressData.firstAddressLine"
              />
            </div>
          </b-col>

        </b-row>

        <!-- address 2-->
        <b-row>
          <b-col cols="12" lg="6" class="NA-input">
            <div class="NA-input-label">{{ data.nameAddress.address2.label }}</div>
            <div>
              <input
                  type="text"
                  :placeholder="data.nameAddress.address2.placeholder"
                  v-model="nameAddressData.secondLineAddress"
              />
            </div>
          </b-col>
        </b-row>

        <!-- city -->
        <b-row>
          <b-col cols="12" lg="6" class="NA-input">
            <div class="NA-input-label">{{ data.nameAddress.city.label }}</div>
            <div>
              <input
                  type="text"
                  required
                  :placeholder="data.nameAddress.city.placeholder"
                  v-model="nameAddressData.townCity"
              />
            </div>
          </b-col>
        </b-row>

        <!-- post code -->
        <b-row>
          <b-col cols="12" lg="6" class="NA-input">
            <div class="NA-input-label">
              {{ data.nameAddress.postCode.label }}
            </div>
            <div>
              <input
                  required
                  type="text"
                  :placeholder="data.nameAddress.postCode.placeholder"
                  v-model="nameAddressData.postCode"
              />
            </div>
          </b-col>
        </b-row>

        <!-- subject -->
        <b-row>
          <b-col cols="12" lg="9" class="NA-input">
            <div class="NA-input-label">
              {{ data.nameAddress.subject.label }}
            </div>
            <div>
              <input
                  required
                  type="text"
                  :placeholder="data.nameAddress.subject.placeholder"
                  v-model="nameAddressData.subject"
              />
            </div>
          </b-col>
        </b-row>
      </div>

      <!-- message -->
      <div class="option-comments-comp px-3">
        <b-row>
          <b-col cols="12" lg="9">
            <div class="OP-comment">
              <div class="OP-comment-title">{{data.nameAddress.message.label }}</div>
              <div>
              <textarea required v-model="nameAddressData.message"
                        :placeholder="data.nameAddress.message.placeholder"/>
              </div>
            </div>
          </b-col>
        </b-row>

      </div>
    </b-container>

    <div class="data-protection-comp px-3">
      <label class="d-flex">
        <div>
          <div class="DP-check"></div>

          <input id="DP-input-chekbox" type="checkbox" v-model="dataProtection" />
          <span class="checkmark"></span>
        </div>
        <div class="pl-3 w-100">
          <p
              v-for="(para, index) in data.dataProtection"
              :key="`${$route.name}_DPText_${index}`"
          >
            {{ para }}
          </p>
        </div>
      </label>
    </div>
    <button :disabled="!dataProtection"
            id="buttonSubmit"
    >{{ data.submitBtn }}</button>

    </form>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import emailjs, { init } from "emailjs-com";

export default {
  name: "Questionnaire",
  props: {
    data: {
      type: Object,
    },
    currentInput: {
      type: Object,
      default() {
        return {
          firstName: "",
          lastName: "",
          firstAddressLine: "",
          secondLineAddress: "",
          townCity: "",
          postCode: "",
          emailAddress: "",
          number: "",
          subject: "",
        };
      },
    },
    currentInputOption: {
      type: Array,
      default() {
        return [];
      },
    },
    currentInputDataProtection: {
      type: Object,
      default() {
        return false;
      },
    },
  },
  data() {
    return {
      rawFeedbackData: [],
      feedBackData: [],
      dataProtection: false,
      nameAddressData: {
        firstName: "",
        lastName: "",
        firstAddressLine: "",
        secondLineAddress: "",
        townCity: "",
        postCode: "",
        emailAddress: "",
        number: "",
        subject: "",
        message: "",
      },
    };
  },
  beforeMount() {
    this.nameAddressData = this.currentInput;
    this.feedBackData = this.currentInputOption;
    this.dataProtection = this.currentInputDataProtection;
  },
  methods: {
    formatFeedback() {
      return {
        name: this.nameAddressData.firstName,
        lastName: this.nameAddressData.lastName,
        firstAddressLine: this.nameAddressData.firstAddressLine,
        secondLineAddress: this.nameAddressData.secondLineAddress,
        townCity: this.nameAddressData.townCity,
        postCode: this.nameAddressData.postCode,
        emailAddress: this.nameAddressData.emailAddress,
        number: this.nameAddressData.number,
        subject: this.nameAddressData.subject,
        message: this.nameAddressData.message,
        agreement: this.dataProtection,
      };
    },
    submitForm() {
      emailjs.init("user_GNIxC3TA5DGfD5pXD5TH8");
      let sendReadyData = this.formatFeedback();
      console.log(sendReadyData)
      emailjs.send("service_osktnts", "template_llhlku7", sendReadyData)
          .then((result) => {
                this.dataProtection = false;
                this.nameAddressData = {
                  firstName: "",
                  lastName: "",
                  firstAddressLine: "",
                  secondLineAddress: "",
                  townCity: "",
                  postCode: "",
                  emailAddress: "",
                  number: "",
                  subject: "",
                  message: "",
                  agreement: false
                };

                Swal.fire({
                  text: "Thank you for submitting your message.",
                  confirmButtonColor: "#105652",
                  confirmButtonText: "OK",
                  width: "500px",
                });
              },
              (error) => {
                alert("error", error);
              }
          );
    },
  },
};
</script>

<style lang="scss">

#buttonSubmit:disabled {
  background-color: #ced4da;
  border: 1px solid #ced4da !important;
  color: darkgrey;

  &:hover {
    background-color: #ced4da !important;
    border: 1px solid #ced4da !important;
    color: darkgrey;
    cursor: default;
  }
}

button {
  float: right !important;

  &:hover {
    color: #ffffff;
  }
}

.name-address-comp {

  $mainText: #05273D;
  $outline: #5B7099;

  p{
    color: $mainText;
  }


  .NA-input {
    margin-bottom: 16px;
    .NA-input-label {
      font-size: 16px;
      color: $mainText;
      font-weight: bold;
    }
    input {
      width: 100%;
      padding: 16px;
      border: $outline solid 1px;
      border-radius: 8px;
      outline: none;
      background: transparent;
      color: $outline;

      &::-webkit-input-placeholder {
        color: rgba($outline, 0.5);
      }
      &::-ms-input-placeholder {
        color: rgba($outline, 0.5);
      }
      &:-ms-input-placeholder {
        color: rgba($outline, 0.5);
      }
    }
  }
}

.option-comments-comp {

  $mainText: #05273D;
  $outline: #5B7099;

  .OP-title {
    color: $mainText;
    font-weight: bold;
  }

  .OP-comment {
    margin: 16px 0;

    .OP-comment-title {
      color: $mainText;
      font-weight: bold;
    }

    textarea {
      width: 100%;
      border: 1px solid $outline;
      border-radius: 8px;
      outline: none;
      padding: 16px;
      margin-top: 4px;
      background: transparent;
      color: $outline;

      &::-webkit-input-placeholder {
        color: rgba($outline, 0.5);
      }
      &::-ms-input-placeholder {
        color: rgba($outline, 0.5);
      }
      &:-ms-input-placeholder {
        color: rgba($outline, 0.5);
      }
    }
  }
}

.data-protection-comp {
  $mainText: #05273D;
  $outline: #5B7099;

  margin: 32px 0;

  .DP-title {
    color: white;
    font-weight: bold;
  }

  input[type='checkbox'] {
    display: block;
    width: 17px;
    height: 17px;
    border: solid 1px white;
    border-radius: 2px;
    background: white;
    margin-top: 5px;
  }

  input:checked {
    border: solid 1px white;
    border-radius: 2px;
    accent-color: white!important;
  }
}

</style>