<template>
  <div class="footer-comp">
    <b-container>
      <slot></slot>
      <b-navbar toggleable="lg" class="footerNavs">
        <b-navbar-nav v-if="footerNavs">
          <b-nav-item
            class="footerNav"
            v-for="(nav, index) in footerNavs"
            :key="`${$route.name}_footerNav_${index}`"
            :to="nav.route"
            >{{ nav.nav }}</b-nav-item
          >
        </b-navbar-nav>
        <b-navbar-nav class="ml-auto">
          <b-nav-item v-if="copyWrite">{{ copyWrite }}</b-nav-item>
        </b-navbar-nav>
      </b-navbar>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "Footer",
  props: {
    footerNavs: {
      type: Array,
      default() {
        return [];
      },
    },
    copyWrite: {
      type: String,
    },
  },
};
</script>

<style lang="scss">
.footer-comp {
  padding: 60px 0;
  width: 100%;
  height: 270px;

  .footerNavs {
    padding-top: 130px;
    .footerNav {
      font-size: 16px;
    }
  }

  @media screen and (max-width: 1220px) {
     padding: 20px 0;
  }
}
</style>