<template>
  <div class="runner-illustration">
    <svg
      viewBox="0 0 454 473"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <title>Group 83</title>
      <defs>
        <polygon
          id="path-1"
          points="0 0 119.035597 0 119.035597 78.4974 0 78.4974"
        ></polygon>
        <polygon
          id="path-3"
          points="-5.68434189e-14 0 35.9613 0 35.9613 84.666 -5.68434189e-14 84.666"
        ></polygon>
      </defs>
      <g
        id="Page-1"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g
          id="Desktop---Home-landscape"
          transform="translate(-48.000000, -3456.000000)"
        >
          <g id="contact-form" transform="translate(0.000000, 2231.000000)">
            <g id="Group-83" transform="translate(48.000000, 1225.000000)">
              <g id="Group-3" transform="translate(141.140499, 0.000000)">
                <mask id="mask-2" fill="white">
                  <use xlink:href="#path-1"></use>
                </mask>
                <g id="Clip-2"></g>
                <path
                  d="M5.95590089,73.1944 C5.95590089,73.1944 -16.2330991,58.4604 25.0459009,40.3164 C25.0459009,40.3164 22.9439009,-0.9136 46.2579009,0.0144 C59.9649009,0.5614 65.4659009,9.7024 67.6709009,17.2254 C71.5549009,30.4804 82.2779009,40.6434 95.8679009,43.1124 C96.6329009,43.2514 97.4179009,43.3804 98.2249009,43.4984 C123.101901,47.1404 125.799901,71.0734 105.648901,78.4974 L5.95590089,73.1944 Z"
                  id="Fill-1"
                  fill="#FFBE4C"
                  mask="url(#mask-2)"
                ></path>
              </g>
              <path
                d="M195.3293,27.2891 L198.9213,48.4431 L180.1993,48.4431 L180.9383,20.2061 C175.8623,21.3371 176.3683,14.1911 176.3683,14.1911 C184.6113,11.3101 186.4363,4.6441 187.0723,1.8881 C187.0723,1.8881 188.4093,10.0451 197.9233,14.7791 C197.9233,14.7791 200.1613,24.8731 195.3293,27.2891"
                id="Fill-4"
                fill="#C49C6B"
              ></path>
              <path
                d="M192.4099,28.1485 C185.2989,27.9525 183.5859,21.9705 183.5859,21.9705"
                id="Stroke-6"
                stroke="#FFFFFF"
              ></path>
              <path
                d="M167.6555,39.9785 C173.1105,36.9865 177.2815,32.4635 178.5655,23.6505"
                id="Stroke-8"
                stroke="#BB8E39"
              ></path>
              <line
                x1="192.84"
                y1="15.5909"
                x2="192.84"
                y2="20.3699"
                id="Stroke-10"
                stroke="#FFFFFF"
              ></line>
              <path
                d="M200.0226,15.5909 C200.0226,15.5909 202.7726,39.9789 228.4506,42.8629"
                id="Stroke-12"
                stroke="#BB8E39"
              ></path>
              <path
                d="M57.8127,72.4737 L65.3667,85.5047 L132.2057,151.0477 C137.1127,155.5447 144.4707,156.0657 149.9627,152.3057 L173.6147,136.0297 L157.3087,86.3717 L139.5267,111.5267 L78.9667,64.9417 C78.9667,64.9417 73.2597,60.3117 71.1547,62.2017 C69.5027,63.6857 71.6947,66.7737 71.6947,66.7737 L62.2677,60.6597 C61.6717,60.2997 60.9377,60.8387 61.1017,61.5147 L62.1627,63.4297 L59.1547,61.5877 C58.2887,61.2657 57.4817,62.1807 57.9097,62.9997 L60.4137,66.4037 L58.6337,65.6257 C57.9067,65.3657 57.2227,66.1057 57.5397,66.8097 L59.4437,69.8537 L57.6737,68.9537 C57.1047,68.8117 56.6107,69.3697 56.8227,69.9177 L57.8127,72.4737 Z"
                id="Fill-14"
                fill="#C49C6B"
              ></path>
              <line
                x1="62.1628"
                y1="63.4297"
                x2="70.3258"
                y2="71.0687"
                id="Stroke-16"
                stroke="#836A47"
              ></line>
              <line
                x1="60.4138"
                y1="66.4034"
                x2="67.4108"
                y2="74.0424"
                id="Stroke-18"
                stroke="#836A47"
              ></line>
              <line
                x1="59.4441"
                y1="69.8535"
                x2="67.8191"
                y2="78.7065"
                id="Stroke-20"
                stroke="#836A47"
              ></line>
              <line
                x1="70.5671"
                y1="65.667"
                x2="74.4651"
                y2="69.494"
                id="Stroke-22"
                stroke="#836A47"
              ></line>
              <path
                d="M157.3083,86.4346 L144.6313,104.3676 L143.2723,154.8016 C145.6123,154.5736 147.9173,153.7686 149.9623,152.3686 L173.6153,136.0916 L157.3083,86.4346 Z"
                id="Fill-24"
                fill="#836A47"
              ></path>
              <polygon
                id="Fill-26"
                fill="#B49063"
                points="98.9685 406.6485 88.6275 451.7425 50.4785 434.5755 59.2525 397.5705"
              ></polygon>
              <polygon
                id="Fill-28"
                fill="#C49C6B"
                points="365.4982 233.3956 402.7692 215.3506 421.1172 254.7876 380.7782 273.7346"
              ></polygon>
              <path
                d="M163.1105,161.6172 L74.5945,265.8522 C68.7505,272.7472 64.7215,280.9922 62.8735,289.8392 L40.3365,397.7442 L113.5785,413.3662 L136.1135,307.3452 L200.2575,232.1802 L163.1105,161.6172 Z"
                id="Fill-30"
                fill="#A25752"
              ></path>
              <path
                d="M160.3991,156.1045 L161.2111,163.6785 L243.7211,325.7605 C253.4521,342.7825 274.8971,349.0695 292.2781,339.9965 L396.0451,285.8335 L360.4481,219.2505 L290.7921,257.1515 L232.9461,151.9895 C230.5931,147.9335 227.5501,144.3185 223.9551,141.3075 L194.4961,120.2175 L160.3991,156.1045 Z"
                id="Fill-32"
                fill="#FF7B7B"
              ></path>
              <polygon
                id="Fill-34"
                fill="#836A47"
                points="88.6272 451.7422 79.0902 472.7232 0.0002 434.1252 3.1732 428.2802 53.5062 421.2642 59.9922 426.7962 57.7352 437.8412"
              ></polygon>
              <polygon
                id="Fill-36"
                fill="#B49063"
                points="402.7687 215.3506 424.2927 207.1106 453.0417 288.8536 447.1277 292.0646 403.8687 257.9646 403.1357 249.4726 414.9977 246.9416"
              ></polygon>
              <polygon
                id="Fill-38"
                fill="#572825"
                points="165.9211 172.9317 197.6431 235.2447 176.7301 259.7507"
              ></polygon>
              <path
                d="M223.9548,141.3076 L194.4958,120.2176 L160.3988,156.1046 L232.3828,151.0656 C230.1138,147.3856 227.2728,144.0866 223.9548,141.3076"
                id="Fill-40"
                fill="#B05E5A"
              ></path>
              <path
                d="M248.7995,205.8086 L258.5335,194.3136 L297.8005,109.3346 C300.3865,103.2026 298.4045,96.0966 293.0175,92.1886 L249.6545,67.1356 L228.4505,103.2036 L258.1175,115.7226 L234.5955,188.4156 C234.5955,188.4156 232.1535,195.3476 234.6405,196.6946 C236.5925,197.7516 238.7655,194.6506 238.7655,194.6506 L236.1745,205.5826 C236.0365,206.2656 236.7905,206.7766 237.3725,206.3946 L238.8195,204.7506 L238.0955,208.2036 C238.0835,209.1266 239.2165,209.5796 239.8445,208.9016 L242.2085,205.3986 L242.0745,207.3366 C242.0725,208.1096 242.9995,208.5046 243.5555,207.9686 L245.7835,205.1536 L245.5305,207.1216 C245.5885,207.7056 246.2795,207.9836 246.7245,207.5996 L248.7995,205.8086 Z"
                id="Fill-42"
                fill="#C49C6B"
              ></path>
              <line
                x1="238.8195"
                y1="204.751"
                x2="243.2705"
                y2="194.496"
                id="Stroke-44"
                stroke="#836A47"
              ></line>
              <line
                x1="242.2082"
                y1="205.3985"
                x2="247.0502"
                y2="196.2425"
                id="Stroke-46"
                stroke="#836A47"
              ></line>
              <line
                x1="245.7834"
                y1="205.1534"
                x2="251.3064"
                y2="194.2904"
                id="Stroke-48"
                stroke="#836A47"
              ></line>
              <line
                x1="238.1018"
                y1="196.084"
                x2="240.3958"
                y2="191.126"
                id="Stroke-50"
                stroke="#836A47"
              ></line>
              <path
                d="M253.7399,69.5664 L219.3259,49.0894 C207.9779,42.3374 194.0549,41.0204 181.8649,46.0944 C121.8589,71.0684 160.3989,156.1044 160.3989,156.1044 L235.2739,141.3364 L220.0149,99.6964 L235.7539,106.2394 L253.7399,69.5664 Z"
                id="Fill-52"
                fill="#A7DED4"
              ></path>
              <polygon
                id="Fill-54"
                fill="#836A47"
                points="235.7536 106.2393 239.8736 108.0233 254.5666 70.0013 253.7396 69.5663"
              ></polygon>
              <polygon
                id="Fill-56"
                fill="#6B5638"
                points="58.3024 401.5762 98.1804 410.0822 96.3274 418.1612"
              ></polygon>
              <polygon
                id="Fill-58"
                fill="#937650"
                points="387.8102 270.4317 375.1052 228.7437 367.3612 232.1807"
              ></polygon>
              <line
                x1="44.5505"
                y1="377.5674"
                x2="117.9525"
                y2="392.7864"
                id="Stroke-60"
                stroke="#572825"
              ></line>
              <line
                x1="374.237"
                y1="297.2159"
                x2="338.555"
                y2="231.1619"
                id="Stroke-62"
                stroke="#A25752"
              ></line>
              <line
                x1="38.1999"
                y1="425.7334"
                x2="44.3719"
                y2="439.5244"
                id="Stroke-64"
                stroke="#5D4B2F"
                stroke-width="0.75"
              ></line>
              <line
                x1="33.927"
                y1="427.6465"
                x2="40.099"
                y2="441.4375"
                id="Stroke-66"
                stroke="#5D4B2F"
                stroke-width="0.75"
              ></line>
              <line
                x1="432.5192"
                y1="258.2569"
                x2="426.3292"
                y2="272.0399"
                id="Stroke-68"
                stroke="#937650"
              ></line>
              <line
                x1="428.2487"
                y1="256.3389"
                x2="422.0587"
                y2="270.1219"
                id="Stroke-70"
                stroke="#937650"
              ></line>
              <path
                d="M71.4484,458.042 C74.1194,453.209 80.2024,451.456 85.0354,454.127"
                id="Stroke-72"
                stroke="#5D4B2F"
                stroke-width="0.75"
              ></path>
              <path
                d="M419.1452,220.5186 C414.7222,223.0156 409.1112,221.4536 406.6142,217.0306"
                id="Stroke-74"
                stroke="#937650"
              ></path>
              <polygon
                id="Fill-76"
                fill="#6B5638"
                points="3.173 428.2803 3.173 428.2803 -9.76996262e-15 434.1253 79.09 472.7233 82.213 465.8523"
              ></polygon>
              <g id="Group-80" transform="translate(417.080400, 207.098700)">
                <mask id="mask-4" fill="white">
                  <use xlink:href="#path-3"></use>
                </mask>
                <g id="Clip-79"></g>
                <polygon
                  id="Fill-78"
                  fill="#937650"
                  mask="url(#mask-4)"
                  points="30.578 84.666 35.962 81.743 7.213 0 -5.68434189e-14 2.761"
                ></polygon>
              </g>
              <line
                x1="243.5114"
                y1="63.4805"
                x2="225.1254"
                y2="101.8215"
                id="Stroke-81"
                stroke="#2F8B88"
              ></line>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: "RunnerIllustration",
};
</script>