<template>
  <div class="container py-5">

    <div class="title-text-comp align-items-center readme pb-5">
      <div>
        <h1 class="smallTitle alt-font">{{ title }}</h1>
        <p class="description"><span>{{ text }}</span> <a class="mailtostyle" :href="'mailto:'+ emailid">{{ emailid }}</a></p>
      </div>

    </div>

    <div class="row" v-if="BoxTileData && BoxTileData.length > 0">
      <div class="col-sm-12 col-md-6 col-lg-3"  v-for="(data, i) in BoxTileData" :key="i">

        <div v-if="i < dataToShow" class="readMeBox">
          <article>
            <div class="content">
              <h2 class="alt-font">{{ data.TileName }}</h2>
            </div>
            <div class="links">
              <a class="link" target="_blank"
                 :href="data.linkhref">
                {{ data.fileType }}
                <span><img :src="data.imagesrc"/></span>
              </a>
            </div>
          </article>
        </div>
      </div>
    </div>

    <div class="titlelink" v-if="dataToShow < totalData || BoxTileData.length > dataToShow">
      <button @click="dataToShow += 12">{{ buttonTitle }}</button>
    </div>

  </div>
</template>
<script>
export default {
  name: "ReadMore",
  data() {
    return {
      dataToShow: 12,
      totalData: 0,
    }
  },
  mounted() {
    this.totalData = this.tiledata.Boxes.length
  },
  props: {
    tiledata: {
      type: Object,
    },
    TileName: {
      type: String,
      default: "",
    },
    linkhref: {
      type: String,
      default: "#",
    },
    imagesrc: {
      type: String,
      default: "",
    },
    fileType: {
      type: String,
      default: "",
    },
    maxLimit: {
      type: Number,
      default: 0,
    },
    textHTML:{
      type: String,
      default: "Title",
    },
    title: {
      type: String,
      default: "Title",
    },
    text: {
      type: String,
      default: "",
    },
    emailid: {
      type: String,
      default: "",
    },

    buttonTitle: {
      type: String,
      default: "Button",
    },
  },
  computed: {
    BoxTileData() {
      if (this.tiledata && this.tiledata.Boxes)
      {
        let newArr = this.tiledata.Boxes.slice(0, this.dataToShow)
        return newArr;
      } else return [];
    },
  },
};

</script>
<style lang="scss">

$mainTeal: #3C7865;
$textTeal: #105652;

.articles-grid-consult {

  .readme{
    text-align: center;
  }

  .readMeBox{
    height:12rem;
  }

  .mailtostyle {
    color: white !important;
    text-decoration: underline;

  }

  .mailtostyle :hover {
    color: white !important;;
    text-decoration: underline !important;;

  }

  .Botton-Space {
    padding-bottom: 15px !important;
  }

  .col-lg-3, .col-md-6, .col-sm-12 {
    margin-bottom: 25px;
  }

  article {
    border: 1px solid #d0d0d0;
    height: 100%;
    background: #fff;
    position: relative;
    overflow-y: hidden;

    .content {
      padding: 25px 25px 66px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
    }

    h2 {
      font-size: 20px;
      line-height: 1.2;
      color: $textTeal;
      margin: 1rem auto 0;
    }

    .links {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      border-top: 1px solid $textTeal;
    }

    .link {
      display: block;
      padding: 8px 25px;
      text-decoration: none;
      color: $textTeal;
      font-weight: 700;
      width: 100%;
      position: relative;

      span {
        width: 20px;
        position: absolute;
        top: 50%;
        right: 25px;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
      }
    }

    .link:hover {
      text-decoration: underline !important;
    }

  }

  .links-titles {
    color: #fff;
    text-align: center;
    font-size: 30px;
  }

  .alt-font {
    font-family: "Museo";
    font-weight: 700;
  }

  .titlelink {
    button {
      color: $textTeal;

      &:hover {
        background: $textTeal!important;
        border: 1px solid #ffffff;
        color: #ffffff;
      }
    }
  }
}
</style>


