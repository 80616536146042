import Vue from 'vue';
import Vuex from 'vuex';

import EnglishData from '../data/content_EN';
import WelshData from '../data/content_WL';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        language: {
            name: 'english',
            code: 'EN'
        },
        content_EN: EnglishData,
        content_WL: WelshData,
        feedback: {}
    },
    mutations: {
        toggleLanguage(state, payload) {
            if (state.language.name === 'english' && payload === 'welsh') {
                state.language = {
                    name: 'welsh',
                    code: 'CY'
                };
            }
            else if (state.language.name === 'english' && payload === 'english') {
                state.language = {
                    name: 'english',
                    code: 'EN'
                };
            }
            else if (state.language.name === 'welsh' && payload === 'english') {
                state.language = {
                    name: 'english',
                    code: 'EN'
                };
            }
            else if (state.language.name === 'welsh' && payload === 'welsh') {
                state.language = {
                    name: 'welsh',
                    code: 'CY'
                };
            }
        },
        storeEnglishContent(state, payload) {
            state.content_EN = payload;
        },
        storeWelshContent(state, payload) {
            state.content_WL = payload;
        },
        storeFeedback(state, payload) {
            state.feedback = payload;
        }
    },
    actions: {
        toggleLanguage({ commit }, language) {
            commit("toggleLanguage", language)
        },
        saveFeedbackData({ commit }, data) {
            commit('storeFeedback', data);
        }
    },
    getters: {
        language: (state) => state.language,
        content: (state) => {
            if (state.language.name === 'english') {
                return state.content_EN
            }
            else if (state.language.name === 'welsh') {
                return state.content_WL;
            }
        },
        feedback: (state) => state.feedback
    }
})