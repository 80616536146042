<template>
  <div class="cycle-illustration">
    <svg
      viewBox="0 0 514 480"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <title>Group 95</title>
      <defs>
        <polygon
          id="path-1"
          points="0 0 103.958267 0 103.958267 76.8558353 0 76.8558353"
        ></polygon>
        <polygon
          id="path-3"
          points="0 5.10823895e-14 202.346065 5.10823895e-14 202.346065 202.442863 0 202.442863"
        ></polygon>
      </defs>
      <g
        id="Page-1"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g
          id="Desktop---Home-landscape"
          transform="translate(-1297.000000, -3449.000000)"
        >
          <g id="contact-form" transform="translate(0.000000, 2231.000000)">
            <g id="Group-95" transform="translate(1297.000000, 1218.000000)">
              <polygon
                id="Fill-1"
                fill="#483921"
                points="215 322 271 322 271 311 215 311"
              ></polygon>
              <g id="Group-94">
                <g id="Group-4" transform="translate(247.300824, 0.000000)">
                  <mask id="mask-2" fill="white">
                    <use xlink:href="#path-1"></use>
                  </mask>
                  <g id="Clip-3"></g>
                  <path
                    d="M8.42244276,43.5759416 C-0.381929382,31.62029 -8.55754611,5.1334534 18.4699542,0.338252199 C32.2468833,-2.10428095 39.8727882,9.20434198 43.6147811,16.1796703 C50.2077306,28.4714173 57.0378108,35.4539348 76.0801174,29.7510962 C109.828115,19.6412735 111.820371,61.8994335 88.0767706,73.3158945 C66.7996876,83.5461364 28.6072878,70.9883887 8.42244276,43.5759416"
                    id="Fill-2"
                    fill="#5E4C30"
                    mask="url(#mask-2)"
                  ></path>
                </g>
                <polygon
                  id="Fill-5"
                  fill="#57B5B0"
                  points="416.041884 279.856246 412.828045 344.476435 406.610553 279.798732"
                ></polygon>
                <path
                  d="M261.640574,29.35551 L268.939526,49.6488447 L287.432121,45.7540917 L274.380955,19.362512 C279.630165,19.4254176 277.643298,12.4680623 277.643298,12.4680623 C277.643298,12.4680623 267.313744,16.0321118 257.999182,7.6548881 C257.999182,7.6548881 255.723088,11.7437497 255.737391,17.8509812 C255.744645,20.3168793 256.364418,27.973385 261.640574,29.35551"
                  id="Fill-7"
                  fill="#C49C6B"
                ></path>
                <path
                  d="M263.048447,29.6450554 C263.693371,29.7924341 264.404763,29.7906368 265.191605,29.6037174 C272.174383,27.9286321 272.621698,21.6632381 272.621698,21.6632381"
                  id="Stroke-9"
                  stroke="#836A47"
                  stroke-width="0.5"
                ></path>
                <line
                  x1="259.728439"
                  y1="17.3272475"
                  x2="261.106312"
                  y2="21.951705"
                  id="Stroke-11"
                  stroke="#836A47"
                  stroke-width="0.5"
                ></line>
                <polygon
                  id="Fill-13"
                  fill="#57B5B0"
                  points="412.827866 384.161038 366.332302 466.168325 385.081788 473.808655"
                ></polygon>
                <polygon
                  id="Fill-15"
                  fill="#57B5B0"
                  points="511.738457 377.024492 420.191669 379.555093 509.562963 399.587819"
                ></polygon>
                <polygon
                  id="Fill-17"
                  fill="#57B5B0"
                  points="326.023152 426.294106 410.959025 383.122917 350.40449 455.563166"
                ></polygon>
                <polygon
                  id="Fill-19"
                  fill="#57B5B0"
                  points="393.710827 281.65175 410.008167 368.251143 373.777484 287.814698"
                ></polygon>
                <polygon
                  id="Fill-21"
                  fill="#57B5B0"
                  points="61.3449704 468.690478 99.2283819 383.095777 80.7142298 474.75098"
                ></polygon>
                <polygon
                  id="Fill-23"
                  fill="#57B5B0"
                  points="196.577504 358.754919 105.641507 381.174462 197.730821 390.545593"
                ></polygon>
                <polygon
                  id="Fill-25"
                  fill="#57B5B0"
                  points="34.1115976 306.491 96.3260469 377.254367 20.8771945 321.307956"
                ></polygon>
                <polygon
                  id="Fill-27"
                  fill="#57B5B0"
                  points="39.2666734 455.460001 78.6213725 407.507989 46.9877898 461.011866"
                ></polygon>
                <polygon
                  id="Fill-29"
                  fill="#57B5B0"
                  points="177.785801 319.293181 105.642406 379.558508 189.640535 339.309731"
                ></polygon>
                <polygon
                  id="Fill-31"
                  fill="#C49C6B"
                  points="358.613521 142.253742 198.774914 142.253742 218.952573 256.781407 281.10774 258.535573 261.939682 198.196557 321.592402 212.898486"
                ></polygon>
                <polygon
                  id="Fill-33"
                  fill="#836A47"
                  points="242.640304 142.253742 280.338682 203.34403 319.098758 212.898486 356.119877 142.253742"
                ></polygon>
                <polygon
                  id="Fill-35"
                  fill="#836A47"
                  points="218.952394 256.780868 174.211955 304.175716 174.211955 312.515197 283.870491 312.515197 281.10756 258.536832"
                ></polygon>
                <polygon
                  id="Fill-37"
                  fill="#5E4C30"
                  points="174.212315 304.175896 174.212315 312.515376 283.869054 312.515376 281.107919 302.292324"
                ></polygon>
                <polygon
                  id="Fill-39"
                  fill="#504026"
                  points="218.952394 256.780868 282.251895 280.888077 281.10756 258.536832"
                ></polygon>
                <path
                  d="M340.892139,200.395736 L383.439119,200.395736 L383.439119,204.998626 C383.439119,213.239255 376.761737,219.918028 368.525041,219.918028 L340.892139,219.918028 L340.892139,200.395736 Z"
                  id="Fill-41"
                  fill="#5E4C30"
                ></path>
                <path
                  d="M365.746661,140.554752 L309.199992,55.2098762 C309.050888,54.9834161 308.889208,54.7605507 308.716749,54.5484691 C302.269311,46.669098 293.437993,42.6269663 284.31565,42.6269663 L276.759806,42.6269663 C264.619438,42.6269663 253.416813,49.1601583 247.436451,59.7318875 L217.637037,112.403614 L175.966722,167.257265 L196.144381,184.811512 L245.548388,138.67118 L266.378156,109.691486 L273.172308,114.012199 C278.730505,117.549289 283.185687,122.574545 286.034847,128.516425 L299.380629,156.363819 L365.746661,140.554752 Z"
                  id="Fill-43"
                  fill="#2F8B88"
                ></path>
                <path
                  d="M274.877133,376.536704 L324.728455,249.756833 L408.656523,376.536704 L274.877133,376.536704 Z M414.696167,377.541396 L326.088363,243.690939 C325.626677,242.995383 324.818277,242.626936 323.984727,242.698828 C323.15477,242.786896 322.441581,243.331479 322.136186,244.107913 L270.062663,376.536704 L264.687703,376.536704 L150.999743,259.791169 L157.148971,244.541063 L261.499014,244.541063 C262.736764,244.541063 263.740977,243.536371 263.740977,242.299827 C263.740977,241.061486 262.736764,240.056795 261.499014,240.056795 L158.956193,240.056795 L175.724202,198.461839 L191.529316,198.461839 C192.76527,198.461839 193.769482,197.458945 193.769482,196.220604 C193.769482,194.982263 192.76527,193.979368 191.529316,193.979368 L174.188242,193.979368 L174.166685,193.979368 L133.858074,193.979368 C132.62212,193.979368 131.617908,194.982263 131.617908,196.220604 L131.617908,210.156882 C131.617908,211.395223 132.62212,212.398117 133.858074,212.398117 C135.095824,212.398117 136.09824,211.395223 136.09824,210.156882 L136.09824,198.461839 L170.893564,198.461839 L153.572251,241.426339 C153.572251,241.426339 153.572251,241.426339 153.572251,241.428136 L146.311024,259.4371 C146.311024,259.438897 146.311024,259.440695 146.309227,259.440695 L98.0046442,379.257819 C97.5429581,380.404498 98.0980593,381.711136 99.245987,382.17484 C99.5208429,382.284475 99.8028847,382.338394 100.08313,382.338394 C100.968777,382.338394 101.809513,381.80819 102.161616,380.932904 L149.16737,264.334747 L262.136752,380.343389 C262.558916,380.774742 263.137371,381.020972 263.740977,381.020972 L271.535747,381.020972 L271.587844,381.020972 L271.639941,381.020972 L412.827866,381.020972 C413.652434,381.020972 414.410533,380.566255 414.800361,379.840145 C415.191986,379.114035 415.150668,378.227965 414.696167,377.541396 L414.696167,377.541396 Z"
                  id="Fill-45"
                  fill="#206965"
                ></path>
                <path
                  d="M100.083489,283.131289 C47.3686359,283.131289 4.48033138,326.038275 4.48033138,378.778299 C4.48033138,431.52012 47.3686359,474.427106 100.083489,474.427106 C152.798343,474.427106 195.686647,431.52012 195.686647,378.778299 C195.686647,326.038275 152.798343,283.131289 100.083489,283.131289 M100.083489,478.909577 C44.8967289,478.909577 0,433.993208 0,378.778299 C0,323.565188 44.8967289,278.648818 100.083489,278.648818 C155.270249,278.648818 200.166978,323.565188 200.166978,378.778299 C200.166978,433.993208 155.270249,478.909577 100.083489,478.909577"
                  id="Fill-47"
                  fill="#206965"
                ></path>
                <g id="Group-51" transform="translate(311.653935, 277.557137)">
                  <mask id="mask-4" fill="white">
                    <use xlink:href="#path-3"></use>
                  </mask>
                  <g id="Clip-50"></g>
                  <path
                    d="M101.173931,4.48426799 C47.8572687,4.48426799 4.48212783,47.8819173 4.48212783,101.220443 C4.48212783,137.035276 24.1190574,169.764142 55.7328793,186.635414 C69.6175951,194.043892 85.3310909,197.958415 101.173931,197.958415 C154.490593,197.958415 197.865734,154.562563 197.865734,101.220443 C197.865734,47.8819173 154.490593,4.48426799 101.173931,4.48426799 M101.173931,202.44448 C84.5963453,202.44448 68.1552897,198.343038 53.623854,190.589478 C20.5477267,172.938177 0,138.694185 0,101.220443 C0,45.40883 45.3871582,0 101.173931,0 C156.960703,0 202.346065,45.40883 202.346065,101.220443 C202.346065,157.03565 156.960703,202.44448 101.173931,202.44448"
                    id="Fill-49"
                    fill="#206965"
                    mask="url(#mask-4)"
                  ></path>
                </g>
                <path
                  d="M175.966542,167.257984 L160.513531,185.367597 C160.513531,185.367597 154.633769,191.589856 157.660778,193.63878 C159.602734,194.952607 162.486026,193.04567 162.486026,193.04567 L156.076314,203.036871 C155.700857,203.669521 156.272126,204.440564 156.987111,204.266225 L159.004518,203.133925 L157.07334,206.320541 C156.737405,207.238963 157.707485,208.087289 158.571575,207.630774 L162.153684,204.965376 L161.341691,206.852543 C161.070428,207.621788 161.855474,208.338911 162.597406,208.001018 L165.802262,205.973662 L164.860925,207.848248 C164.713617,208.452141 165.306443,208.969764 165.883102,208.743304 L168.577768,207.684693 L181.73672,199.965282 C182.112177,199.746011 182.453501,199.472821 182.751711,199.154699 L196.144201,184.812231 L175.966542,167.257984 Z"
                  id="Fill-52"
                  fill="#C49C6B"
                ></path>
                <line
                  x1="159.003979"
                  y1="203.133925"
                  x2="167.028694"
                  y2="194.469133"
                  id="Stroke-54"
                  stroke="#836A47"
                  stroke-width="0.75"
                ></line>
                <line
                  x1="162.153864"
                  y1="204.966274"
                  x2="170.185765"
                  y2="197.534431"
                  id="Stroke-56"
                  stroke="#836A47"
                  stroke-width="0.75"
                ></line>
                <line
                  x1="165.801903"
                  y1="205.973662"
                  x2="175.109279"
                  y2="197.078815"
                  id="Stroke-58"
                  stroke="#836A47"
                  stroke-width="0.75"
                ></line>
                <line
                  x1="162.581058"
                  y1="192.878341"
                  x2="167.769188"
                  y2="187.540355"
                  id="Stroke-60"
                  stroke="#836A47"
                  stroke-width="0.75"
                ></line>
                <polygon
                  id="Fill-62"
                  fill="#5E4C30"
                  points="355.574117 397.30219 310.490558 424.157474 304.844334 414.669517 349.927893 387.816031"
                ></polygon>
                <polygon
                  id="Fill-64"
                  fill="#C49C6B"
                  points="342.898228 335.648088 366.332841 378.779737 282.553877 428.369091 278.695115 422.224116 288.054589 361.293788"
                ></polygon>
                <polygon
                  id="Fill-66"
                  fill="#A4845A"
                  points="362.117844 371.021863 366.332302 378.779018 282.553338 428.368372 278.694577 422.223397"
                ></polygon>
                <polygon
                  id="Fill-68"
                  fill="#836A47"
                  points="288.05405 361.292889 349.427763 347.665747 342.897689 335.64719"
                ></polygon>
                <path
                  d="M299.380091,156.363639 L358.614239,142.253023 L372.759439,175.675647 C377.331389,186.479228 373.330709,199.004624 363.34248,205.153194 L356.26449,209.511651 L306.901802,264.072341 L342.898947,335.648088 L288.053511,361.293788 L242.373532,276.466535 C238.142907,268.612326 238.823759,259.020126 244.119676,251.8435 L299.967528,176.155527 C301.099287,174.622428 301.58792,172.711896 301.332825,170.824729 L299.380091,156.363639 Z"
                  id="Fill-70"
                  fill="#FFBE4C"
                ></path>
                <polygon
                  id="Fill-72"
                  fill="#836A47"
                  points="299.380091 156.363639 362.020297 150.303137 358.614239 142.253023"
                ></polygon>
                <line
                  x1="289.02413"
                  y1="378.123362"
                  x2="309.891623"
                  y2="378.123362"
                  id="Stroke-74"
                  stroke="#836A47"
                  stroke-width="0.75"
                ></line>
                <line
                  x1="289.02413"
                  y1="384.161038"
                  x2="309.891623"
                  y2="384.161038"
                  id="Stroke-76"
                  stroke="#836A47"
                  stroke-width="0.75"
                ></line>
                <line
                  x1="205.642181"
                  y1="274.543242"
                  x2="223.669496"
                  y2="285.061052"
                  id="Stroke-78"
                  stroke="#5D4B2F"
                  stroke-width="0.75"
                ></line>
                <line
                  x1="202.60098"
                  y1="279.759551"
                  x2="220.628296"
                  y2="290.277361"
                  id="Stroke-80"
                  stroke="#5D4B2F"
                  stroke-width="0.75"
                ></line>
                <line
                  x1="282.615495"
                  y1="351.193671"
                  x2="337.178889"
                  y2="324.277279"
                  id="Stroke-82"
                  stroke="#836A47"
                  stroke-width="0.75"
                ></line>
                <path
                  d="M343.170929,377.024492 C338.683411,369.484811 341.157115,359.730854 348.694994,355.241194"
                  id="Stroke-84"
                  stroke="#836A47"
                  stroke-width="0.75"
                ></path>
                <polygon
                  id="Fill-86"
                  fill="#105652"
                  points="273.839507 75.9922566 266.395043 109.704246 272.705951 113.719419"
                ></polygon>
                <line
                  x1="224.359151"
                  y1="100.520933"
                  x2="257.009521"
                  y2="122.724799"
                  id="Stroke-88"
                  stroke="#105652"
                  stroke-width="0.75"
                ></line>
                <line
                  x1="181.675282"
                  y1="159.744184"
                  x2="203.622438"
                  y2="177.828635"
                  id="Stroke-90"
                  stroke="#105652"
                  stroke-width="0.75"
                ></line>
                <path
                  d="M280.112151,17.3272475 C280.112151,17.3272475 285.232016,26.2418644 295.529233,29.3799533"
                  id="Stroke-92"
                  stroke="#836A47"
                  stroke-width="0.5"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: "Cycleillustration",
};
</script>