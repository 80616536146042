import Vue from 'vue';
import VueRouter from 'vue-router';

import LandingPage from '../views/LandingPage';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'LandingPage',
        component: LandingPage
    },
    {
        path: '/cookies',
        name: 'cookies'
    }
];

const router = new VueRouter({
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        return { x: 0, y: 0,  behavior: 'smooth' }
    },
});

export default router;