export default {
    banner: {
        title: 'Cyfnewidfa Trafnidiaeth Gyhoeddus Hwlffordd',
        text: [
            `Mae Cyngor Sir Penfro wedi bod yn gweithio ar y cyd â thîm o ymgynghorwyr i ymgymryd â'r gwaith o ddylunio cyfnewidfa trafnidiaeth gyhoeddus fodern newydd. Bydd y gyfnewidfa newydd yn cymryd lle'r maes parcio aml-lawr a’r orsaf fysiau flaenorol yn Hwlffordd.`,
            `Cwblhawyd y dyluniad manwl cychwynnol gan AtkinsRéalis (Atkins) ym mis Mawrth 2023 a chwblhawyd y gwaith dymchwel ar y safle ym mis Ebrill 2023. `,
            `Yn dilyn ymarfer tendro llwyddiannus ym mis Tachwedd 2023, penodwyd Kier Construction Cymru a Gorllewin Lloegr i ymgymryd â gwaith mireinio er mwyn lleihau cost gyffredinol y cynllun. Gwnaed hyn i sicrhau gwerth am arian wrth gynnig profiad cyfnewidfa well ac opsiynau trafnidiaeth gyhoeddus gynaliadwy i ddefnyddwyr.`
        ],
        button: 'Mwy o wybodaeth',
        brandLogo: 'https://firebasestorage.googleapis.com/v0/b/haverfordwest-60687.appspot.com/o/Branding%2Flogo_CY_cropped.png?alt=media&token=a6114334-5a6d-4504-b28b-5dd50066b622',
        company: 'https://firebasestorage.googleapis.com/v0/b/haverfordwest-60687.appspot.com/o/Branding%2FAtkinsRealis.png?alt=media&token=b8c95f5e-39c9-43df-8a66-56aa8c5753b9',
        company2: 'https://firebasestorage.googleapis.com/v0/b/haverfordwest-60687.appspot.com/o/Branding%2FWorking%20obo%20WG_white.png?alt=media&token=c616031c-cff0-48f8-9faf-dc47fbc7d646',
        navs: {
            pages: [
                /* { nav: "Diben y Prosiect", route: "projectPurpose", order: 1 },
                 { nav: "Dogfennau", route: "readmore", order: 2 },
                 { nav: "Cynlluniau Presennol", route: "currentplan", order: 3 },
                 { nav: "Adeiladu", route: "construction", order: 4 },
                 { nav: "Cyswllt", route: "feedback", order: 5 }*/
            ]
        }
    },
    section1: {
        title: 'Diben y Prosiect',
        text: [
            'Mae’r gyfnewidfa yn rhan sylfaenol o’r cynlluniau adfywio ehangach yn Hwlffordd ac yn dangos dull yr ' +
            'awdurdodau o adfywio, sydd yn unol â gofynion datblygiad sy\'n seiliedig ar systemau trafnidiaeth ' +
            'Llywodraeth Cymru. Mae trafnidiaeth yn rhwystr allweddol i ddatblygiad mewn llawer o ardaloedd, ' +
            'ac mae darparu cyfleuster modern o ansawdd uchel yn sicrhau atyniad Hwlffordd, ynghyd ag adfywio ' +
            'ehangach i ddarparu cynhyrchwyr teithiau ac atyniadau gyda chynlluniau eraill yng nghanol y dref, ' +
            'megis y bont unigryw a gwaith ochr Cei’r Gorllewin.',
            'Cymerwch gip ar daith rithwir o\'r gyfnewidfa newydd trwy\'r ddolen isod.',
        ],
        button: 'Gwyliwch y fideo',
        buttonLink: 'https://vimeo.com/859401033?share=copy'
    },
    section2: {
        title: 'Cynlluniau Presennol',
        text: [
            `Mae dyluniad manwl y gyfnewidfa wedi'i gwblhau gan AtkinsRéalis (Atkins) gyda mewnbwn Cyngor Sir Penfro a thrigolion lleol.`,
            `Disgwylir i'r gwaith adeiladu ddechrau yn hwyr yn hydref 2024 yn dilyn cyfnod o fireinio'r dyluniad gan y contractwr adeiladu penodedig, Kier Construction.`,
            `Mae'r cynllun yn cael ei ariannu gan Gronfa Trafnidiaeth Leol Llywodraeth Cymru, gydag arian cyfatebol gan Gyngor Sir Penfro`,
            `Yn ystod y gwaith adeiladu, mae Kier Construction wedi ymrwymo i ddarparu nifer o fentrau budd cymunedol a gwerth cymdeithasol y byddwn yn sôn amdanynt wrth i ni agosáu at y cyfnod adeiladu.`,
            `Bydd diweddariadau pellach yn cael eu darparu yma wrth i’r cynllun symud ymlaen i’r cyfnod adeiladu.`
        ]
    },
    section3: {
        title: `Beth sy'n digwydd nawr?`,
        imageLink: 'https://firebasestorage.googleapis.com/v0/b/haverfordwest-60687.appspot.com/o/BackgroundImages%2FTimelineX_wel.png?alt=media&token=1eb53ead-da21-4b78-8da1-0fbd1754cc6b',
        imageAltText: '',
        text: [
            `Mae’r cyfnod adeiladu bellach wedi’i raglennu i ddechrau yn hydref 2024 ar ôl cyfnod o fireinio’r dyluniad, a disgwylir iddo gael ei gwblhau yn gynnar yng ngwanwyn 2026.`,
            `Unwaith y bydd y gwaith adeiladu’n dechrau, bydd y maes parcio dros dro presennol yn cael ei ddileu, a bydd y cyhoedd yn cael gwybod ymlaen llaw.`,
            `Bydd Cartlett Road yn parhau i fod yn weithredol yn ystod y gwaith, gydag un lôn ar gau lle bo angen, tra bydd Canolfan Siopa Glan-yr-afon yn parhau i fod ar agor ac yn weithredol trwy gydol y gwaith adeiladu.`

        ]
    },
    section4: {
        title: "Dogfennau a chynlluniau adeiladu",
        text:
            `Os na allwch gyrchu'r dogfennau yn electronig gallwch ofyn am gopïau o'r wybodaeth hon trwy e-bostio`,
        emailid: 'hwpti@atkinsglobal.com',
        buttonTitle: "Dangos y cyfan",
        tiles:
        {
            Boxes: [
                {
                    no: 1,
                    TileName: "Asesiad ansawdd aer",
                    linkhref: "https://firebasestorage.googleapis.com/v0/b/haverfordwest-60687.appspot.com/o/Documents%2FAir%20Quality%20assessment_5201245%20.pdf?alt=media&token=0e309d6b-43fa-4773-9083-ad29f76e1ca9",
                    fileType: "Dogfennau",
                    imagesrc: "https://firebasestorage.googleapis.com/v0/b/haverfordwest-60687.appspot.com/o/baseline-arrow_forward-24px_green.png?alt=media&token=d88b3b40-6bf1-47ca-95d2-d6ed01bde82d"
                },
                {
                    no: 2,
                    TileName: "Lluniadau pensaernïaeth",
                    linkhref: "https://firebasestorage.googleapis.com/v0/b/haverfordwest-60687.appspot.com/o/Documents%2FArchitecture%20Website%20Compilaiton.pdf?alt=media&token=ba92b862-6f2d-4bf8-8b66-fed78b4296af",
                    fileType: "Lluniadau",
                    imagesrc: "https://firebasestorage.googleapis.com/v0/b/haverfordwest-60687.appspot.com/o/baseline-arrow_forward-24px_green.png?alt=media&token=d88b3b40-6bf1-47ca-95d2-d6ed01bde82d"
                },
                {
                    no: 3,
                    TileName: "Rendradau adeiladu",
                    linkhref: "https://firebasestorage.googleapis.com/v0/b/haverfordwest-60687.appspot.com/o/Documents%2FConstructionRenders%203.0.pdf?alt=media&token=407c8895-9023-4e1d-a1f5-2d42bb4b5a42",
                    fileType: "Delweddau",
                    imagesrc: "https://firebasestorage.googleapis.com/v0/b/haverfordwest-60687.appspot.com/o/baseline-arrow_forward-24px_green.png?alt=media&token=d88b3b40-6bf1-47ca-95d2-d6ed01bde82d"
                },
                {
                    no: 4,
                    TileName: "Lluniau'r gwaith dymchwel",
                    linkhref: "https://firebasestorage.googleapis.com/v0/b/haverfordwest-60687.appspot.com/o/Documents%2FDemolitionPhotos.pdf?alt=media&token=cf63f1e5-4865-4d20-a0ac-311841532315",
                    fileType: "Delweddau",
                    imagesrc: "https://firebasestorage.googleapis.com/v0/b/haverfordwest-60687.appspot.com/o/baseline-arrow_forward-24px_green.png?alt=media&token=d88b3b40-6bf1-47ca-95d2-d6ed01bde82d"
                },
                {
                    no: 5,
                    TileName: "Lluniadau draenio",
                    linkhref: "https://firebasestorage.googleapis.com/v0/b/haverfordwest-60687.appspot.com/o/Documents%2FDrainage%20Website%20Compilation.pdf?alt=media&token=58376848-12b5-44ac-a413-976f100bc538",
                    fileType: "Lluniadau",
                    imagesrc: "https://firebasestorage.googleapis.com/v0/b/haverfordwest-60687.appspot.com/o/baseline-arrow_forward-24px_green.png?alt=media&token=d88b3b40-6bf1-47ca-95d2-d6ed01bde82d"
                },
                {
                    no: 6,
                    TileName: "Asesiad canlyniadau alifogydd",
                    linkhref: "https://firebasestorage.googleapis.com/v0/b/haverfordwest-60687.appspot.com/o/Documents%2FFlood%20consequences%20assessment_CS101371_FCA_v0.1.pdf?alt=media&token=629f99cf-cff2-47f7-8b83-fb0af13eae47",
                    fileType: "Dogfennau",
                    imagesrc: "https://firebasestorage.googleapis.com/v0/b/haverfordwest-60687.appspot.com/o/baseline-arrow_forward-24px_green.png?alt=media&token=d88b3b40-6bf1-47ca-95d2-d6ed01bde82d"
                },
                {
                    no: 7,
                    TileName: "Datganiad effaith treftadaeth",
                    linkhref: "https://firebasestorage.googleapis.com/v0/b/haverfordwest-60687.appspot.com/o/Documents%2FHeritage%20Impact%20Statement_PE09-ATK-EHR-SWMWREC_BS-RP-LH-000002.pdf?alt=media&token=f27cb960-b728-400b-8ca8-6e22f6b1382d",
                    fileType: "Dogfennau",
                    imagesrc: "https://firebasestorage.googleapis.com/v0/b/haverfordwest-60687.appspot.com/o/baseline-arrow_forward-24px_green.png?alt=media&token=d88b3b40-6bf1-47ca-95d2-d6ed01bde82d"
                },
                {
                    no: 8,
                    TileName: "Lluniadau priffyrdd",
                    linkhref: "https://firebasestorage.googleapis.com/v0/b/haverfordwest-60687.appspot.com/o/Documents%2FHPTI%20Website%20Highways%20Compilation.pdf?alt=media&token=ccb2c369-ca92-477d-b09b-01b7dfeaf1a9",
                    fileType: "Lluniadau",
                    imagesrc: "https://firebasestorage.googleapis.com/v0/b/haverfordwest-60687.appspot.com/o/baseline-arrow_forward-24px_green.png?alt=media&token=d88b3b40-6bf1-47ca-95d2-d6ed01bde82d"
                },
                {
                    no: 9,
                    TileName: "Lluniadau tirwedd",
                    linkhref: "https://firebasestorage.googleapis.com/v0/b/haverfordwest-60687.appspot.com/o/Documents%2FLandscape%20Website%20compilation%202.pdf?alt=media&token=94f6b74b-8f58-44b3-9c4f-77324d55a248",
                    fileType: "Lluniadau",
                    imagesrc: "https://firebasestorage.googleapis.com/v0/b/haverfordwest-60687.appspot.com/o/baseline-arrow_forward-24px_green.png?alt=media&token=d88b3b40-6bf1-47ca-95d2-d6ed01bde82d"
                },
                {
                    no: 10,
                    TileName: "Datganiad Cynllunio, Dylunio a Mynediad",
                    linkhref: "https://firebasestorage.googleapis.com/v0/b/haverfordwest-60687.appspot.com/o/Documents%2FPDAS_PE09-ATK-LDC-SWMWREC_BS-RP-CX-000003_PDAS.pdf?alt=media&token=1e726c5c-798e-44ec-ac14-33235348c896",
                    fileType: "Dogfennau",
                    imagesrc: "https://firebasestorage.googleapis.com/v0/b/haverfordwest-60687.appspot.com/o/baseline-arrow_forward-24px_green.png?alt=media&token=d88b3b40-6bf1-47ca-95d2-d6ed01bde82d"
                },
                {
                    no: 11,
                    TileName: "Lluniadau strwythurau",
                    linkhref: "https://firebasestorage.googleapis.com/v0/b/haverfordwest-60687.appspot.com/o/Documents%2FStructures%20Website%20Compilation.pdf?alt=media&token=061d3bdd-537d-4721-a6ce-bd666e801941",
                    fileType: "Lluniadau",
                    imagesrc: "https://firebasestorage.googleapis.com/v0/b/haverfordwest-60687.appspot.com/o/baseline-arrow_forward-24px_green.png?alt=media&token=d88b3b40-6bf1-47ca-95d2-d6ed01bde82d"
                },
                {
                    no: 12,
                    TileName: "Asesiad trafnidiaeth",
                    linkhref: "https://firebasestorage.googleapis.com/v0/b/haverfordwest-60687.appspot.com/o/Documents%2FTransportAssessment_PE09-ATK-HTA-SWMWREC-RP-TR-000001_TA.pdf?alt=media&token=5dfe6f29-ee7f-40f3-b98b-0c420ef8b087",
                    fileType: "Dogfennau",
                    imagesrc: "https://firebasestorage.googleapis.com/v0/b/haverfordwest-60687.appspot.com/o/baseline-arrow_forward-24px_green.png?alt=media&token=d88b3b40-6bf1-47ca-95d2-d6ed01bde82d"
                },
            ]
        },
    },
    feedback: {
        title: `Angen cysylltu â'r tîm?`,
        text: [
            'Llenwch y ffurflen isod i gyflwyno\'ch ymholiad i\'r tîm neu fel arall ysgrifennwch at.',
        ],
        textAddress: [
            'Cyfnewidfa Trafnidiaeth Gyhoeddus Hwlffordd, AtkinsRéalis,',
            '12 Orchard St,',
            'Abertawe, Gorllewin Morgannwg,',
            'SA1 5AD',
        ],
        commentBoxes: [
            { optionDetail: "Ymholiad", placeholder: 'Rhowch eich ymholiad' }
        ],
        dataProtection: [
            `Bydd sylwadau'n cael eu cofnodi ar gyfer trafodaeth ac ystyriaeth 
            o reolaeth y cynllun a'r gwaith. Drwy dicio’r blwch hwn, rydych yn cytuno i’r 
            wybodaeth hon gael ei chofnodi. Mae preifatrwydd a diogelwch y data hwn yn 
            flaenoriaeth i Atkins a bydd yn cael ei ddefnyddio ar gyfer prosiect 
            Cyfnewidfa Trafnidiaeth Gyhoeddus Hwlffordd yn unig. Os ydych am 
            ddeall sut ydym yn trin y data hwn, ewch i’n hysbysiad preifatrwydd. `
        ],
        submitBtn: 'Danfon',
        nameAddress: {
            fname: {
                label: 'Enw cyntaf',
                placeholder: 'Rhowch eich enw cyntaf'
            },
            lname: {
                label: 'Cyfenw',
                placeholder: 'Rhowch eich cyfenw'
            },
            number: {
                label: 'Rhif ffôn',
                placeholder: 'Nodwch eich rhif ffôn'
            },
            email: {
                label: 'Cyfeiriad e-bost',
                placeholder: 'Nodwch eich cyfeiriad e-bost'
            },
            address1: {
                label: 'Llinell gyntaf eich cyfeiriad',
                placeholder: 'Rhowch linell gyntaf eich cyfeiriad'
            },
            address2: {
                label: 'Ail linell y cyfeiriad',
                placeholder: 'Rhowch ail linell eich cyfeiriad'
            },
            city: {
                label: 'Dinas',
                placeholder: "Nodwch eich dinas"
            },
            postCode: {
                label: 'Cod Post',
                placeholder: 'Nodwch eich cod post'
            },
            subject: {
                label: "Pwnc",
                placeholder: 'Rhowch eich pwnc'
            },
            message: {
                label: "Ymholiad",
                placeholder: 'Nodwch eich ymholiad'
            },
        }
    },
    footer: {
        brandLogo: 'https://firebasestorage.googleapis.com/v0/b/newgale-landingpage.appspot.com/o/MicrosoftTeams-image%20(4).png?alt=media&token=6c3e62e8-929a-4dfa-8ba0-83febbcada91',
        footerText: '© 2023 Hawlfraint Gyngor Sir Penfro',
        links: 'Dilynwch ni',
        policy: "Gweld ein polisi preifatrwydd"
    }
}