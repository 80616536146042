<template>
  <div class="banner-comp">
    <div v-if="main" class="main">
      <slot></slot>
    </div>
    <div v-else class="page">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "Banner",
  props: {
    main: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.banner-comp {
  overflow: hidden;
  position: relative;

  .main {
    width: 100%;
  }

  .page {
    width: 100%;
    height: 637px;
    padding-top: 56px;

    @media screen and (max-width: 768px) {
      height: 370px;
    }
  }
}
</style>