<template>
  <div class="LandingPage">

    <section class="landingBannerImage py-5" id="header">
      <div class="overlay-teal">
        <Banner :main="true">
          <b-container>
            <Header class="pb-5"
              :currentLanguage="currentLanguage"
              :navbar="bannerData.navs"
                    :branding="bannerData"
            >
            </Header>
            <div class="mainTitle py-5">
              <TitleText
                :main="true"
                :title="bannerData.title"
                :paragraphs="bannerData.text"
                :useParagraphs="true"
                :button="true"
                :buttonText="bannerData.button"
                :link="'#projectPurpose'"
                :new-tab="false"
              />
            </div>
          </b-container>
        </Banner>
      </div>
    </section>

    <section class="darkText landingImage1" id="projectPurpose" ref="projectPurpose">
      <b-container>
        <TitleText
            :title="section1Data.title"
            :paragraphs="section1Data.text"
            :smallTitle="true"
            :useParagraphs="true"
            :button="true"
            :buttonText="section1Data.button"
            :link="section1Data.buttonLink"
            :darkButton="true"
        />
      </b-container>
    </section>

    <section class="articles-grid-consult" id="readmore">
      <ReadMore
          :title="section4Data.title"
          :text="section4Data.text"
          :emailid="section4Data.emailid"
          :buttonTitle="section4Data.buttonTitle"
          :tiledata="section4Data.tiles"
          :text-h-t-m-l="section4Data.textHTML"
      />
    </section>

    <section class="landingImage2" id="currentplan">
      <b-container>
        <TitleText class="pt-5"
          :title="section2Data.title"
          :smallTitle="true"
          :paragraphs="section2Data.text"
          :useParagraphs="true"
        />
      </b-container>
    </section>

    <section class="tealBG " id="construction">
      <b-container>
        <div class="row">
          <div class="col-lg-5">
            <img class="timelineImage" :src="section3Data.imageLink" :alt="section3Data.imageAltText"/>
          </div>
          <TitleText class="col-lg-7"
                     :title="section3Data.title"
                     :paragraphs="section3Data.text"
                     :smallTitle="true"
                     :useParagraphs="true"
          />
        </div>
      </b-container>
    </section>

    <section class="articles-grid-consult" id="feedback">
      <b-container>
        <TitleText
            :title="feedbackData.title"
            :smallTitle="true"
            :centerText="true"
        />
        <Questionnaire :data="feedbackData" />
      </b-container>


    </section>

    <section class="footer">
      <footer>
        <cookie-law theme="dark-lime"></cookie-law>
      </footer>
      <Footer>
        <b-row>
          <b-col cols="12" lg="6">
            <div class="brandLogo">
              <img :src="footerData.brandLogo" />
            </div>
          </b-col>
          <b-col cols="12" lg="6">
            <div class="footerText">
              <p>
                {{ footerData.links }}
                <a
                  href="https://www.facebook.com/PembrokeshireCountyCouncil"
                  target="_blank"
                  ><i class="fab fa-facebook-square"></i
                ></a>
                <a href="https://twitter.com/pembrokeshire" target="_blank"
                  ><i class="fab fa-twitter-square"></i
                ></a>
              </p>
              <p>{{ footerData.footerText }}</p>
              <p>
                <a
                  class="policy-link"
                  href="https://www.pembrokeshire.gov.uk/privacy-promise"
                  target="_blank"
                  >{{ footerData.policy }}</a>
              </p>
            </div>
          </b-col>
        </b-row>
      </Footer>
    </section>
  </div>
</template>

<script>
import Banner from "../components/Banner";
import Header from "../components/Header";
import TitleText from "../components/TitleText";
import Questionnaire from "../components/Project/Questionnaire";
import Footer from "../components/Footer";
import Cycleillustration from "../components/Project/CycleIllustration";
import RunnerIllustration from "../components/Project/RunnerIllustration";
import CookieLaw from "vue-cookie-law";
import ReadMore from "../components/ReadMore";
import { mapGetters } from "vuex";

export default {
  name: "LandingPage",
  components: {
    Banner,
    Header,
    TitleText,
    Questionnaire,
    Footer,
    Cycleillustration,
    RunnerIllustration,
    CookieLaw,
    ReadMore,
  },
  computed: {
    ...mapGetters(["language", "content"]),
    bannerData() {
      if (this.content && this.content.banner) {
        return this.content.banner;
      } else return {};
    },
    section1Data() {
      if (this.content && this.content.section1) {
        return this.content.section1;
      } else return {};
    },
    section2Data() {
      if (this.content && this.content.section2) {
        return this.content.section2;
      } else return {};
    },
    section3Data() {
      if (this.content && this.content.section3) {
        return this.content.section3;
      } else return {};
    },
    section4Data() {
      if (this.content && this.content.section4) {
        return this.content.section4;
      } else return {};
    },
    feedbackData() {
      if (this.content && this.content.feedback) {
        return this.content.feedback;
      } else return {};
    },
    footerData() {
      if (this.content && this.content.footer) {
        return this.content.footer;
      } else return {};
    },
    currentLanguage() {
      if (this.language) {
        return this.language;
      } else return "english";
    },
  },
};
</script>

