<template>
  <div class="title-text-comp py-5" :class="centerText ? 'w-100' : 'w-75'" >
    <h1 v-if="title" :class="[(smallTitle ? 'smallTitle' : ''), (centerText ? 'center' : '')]"
        v-html="title"/>
    <p class="mainText" v-if="text && main">
      {{ text }}
    </p>
    <p v-if="text && !main">
      {{ text }}
    </p>
    <div v-if="useParagraphs">
      <p
        v-for="(paragraph, index) in paragraphs"
        :key="`${$route.name}_paragraphs_${index}`"
      >
        {{ paragraph }}
      </p>
    </div>
    <div v-if="button" :class="darkButton ? 'darkButton' : 'titlelink'" class="pt-5">
      <button v-if="link" @click="openLink(link)">
        <a>{{ buttonText }}</a>
      </button>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "TitleText",
  props: {
    title: {
      type: String,
      default: "Title",
    },
    text: {
      type: String,
      default: "",
    },
    button: {
      type: Boolean,
      default: false,
    },
    buttonText: {
      type: String,
      default: "Button",
    },
    main: {
      type: Boolean,
      default: false,
    },
    newTab: {
      type: Boolean,
      default: true,
    },
    centerText: {
      type: Boolean,
      default: false,
    },
    link: {
      type: String,
      default: "#",
    },
    smallTitle: {
      type: Boolean,
      default: false,
    },
    darkButton: {
      type: Boolean,
      default: false,
    },
    useParagraphs: {
      type: Boolean,
      default: false,
    },
    paragraphs: {
      type: Array,
      default() {
        return ["Paragraph1", "Paragraph2", "Paragraph3"];
      },
    },
  },
  methods: {
    openLink(link)
    {
      console.log(link, this.newTab)
      window.open(link, this.newTab ? '_blank' : '_self');
    }
  },
};
</script>

<style lang="scss">
.title-text-comp {

  position: relative;

  .center{
    text-align: center;
  }

  button{
    float: left!important;
  }

  h1 {
    font-size: 56px;

    &.smallTitle {
      font-size: 32px;
    }

    @media screen and (max-width: 500px) {
      font-size: 24px;
    }

    @media screen and (max-width: 769px) {
      font-size: 30px;
    }
  }
  p {
    &.mainText {
      font-size: 20px;
      @media screen and (max-width: 500px) {
        font-size: 16px;
      }

      @media screen and (max-width: 769px) {
        font-size: 20px;
      }

    }
  }



  .description{
    margin-left: auto;
    margin-right: auto;
    max-width: 39.375rem;
  }
}
</style>