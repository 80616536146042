<template>
  <div class="row header-comp">

    <div class="brandLogo col-lg-6 col-md-8 col-sm-11">
      <img class="companyLogo" :src="branding.brandLogo" />
      <img class="companyLogo2" :src="branding.company" />
      <img class="companyLogo3" :src="branding.company2" />
    </div>

    <b-navbar toggleable="lg" v-if="navbar" type="dark" class="col-lg-6 col-md-4 col-sm-1">
      <slot></slot>

      <!--todo: enable if need the burger menu in mobile-->
      <!--      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>-->
<!--      <b-collapse id="nav-collapse" is-nav>-->
        <b-navbar-nav class="ml-auto" v-if="navbar.pages">
<!--
          <button
              v-for="(nav, index) in navbar.pages"
              :key="`${$route.name}_nav_${index}`"
              @click="scrollToView(nav.route)"
              class="px-2 navigation-button"
          >
            <span :class="nav.name === $route.name ? 'active' : ''"
                  v-html="nav.nav"/>
          </button>
-->


          <b-nav-item-dropdown class="px-2 pt-1">
            <template #button-content>
              <span>{{ currentLanguage.code }}</span>
            </template>
            <b-dropdown-item @click="() => toggleLang('english')"
                             v-if="languageTranslations(currentLanguage.name)[0]">
              {{languageTranslations(currentLanguage.name)[0] }}
            </b-dropdown-item>
            <b-dropdown-item @click="() => toggleLang('welsh')"
                             v-if="languageTranslations(currentLanguage.name)[1]">
              {{languageTranslations(currentLanguage.name)[1] }}
            </b-dropdown-item>
          </b-nav-item-dropdown>

        </b-navbar-nav>
<!--      </b-collapse>-->
    </b-navbar>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "Header",
  props: {
    branding:{
      type: Object,
    },
    navbar: {
      type: Object,
      default() {
        return {
          title: "Navbar",
          home: {nav: "Home", route: "/"},
          pages: [{nav: "Link", route: "#feedback", order: 1}],
        };
      },
    },
    currentLanguage: {
      type: Object,
      default() {
        return {
          name: "english",
          code: "EN",
        };
      },
    },
  },
  methods: {
    scrollToView(target) {
      document.getElementById(target).scrollIntoView({behavior: "smooth"})
    },
    ...mapActions(["toggleLanguage"]),
    toggleLang(lang) {
      this.toggleLanguage(lang);
    },
    languageTranslations(currentlang) {
      if (currentlang === "english") {
        return ["English", "Cymraeg"];
      } else if (currentlang === "welsh") {
        return ["English", "Cymraeg"];
      } else return [];
    },
  },
};
</script>

<style lang="scss">

.navbar{
  padding: 0 !important;

  .nav-link{
    color: #FFFFFF !important;
  }
}

.header-comp {
  .active {
    border-bottom: 3px solid white;
    font-weight: bold;
  }

  .dropdown-menu {
    border-color: white;
    background: white;
    min-width: 0 !important;

    a {
      color: #27606c !important;
    }
  }

  .navigation-button {
    color: white !important;
    background: transparent;

    &:hover {
      text-decoration: underline;
      background: transparent;
      border: none;
    }
  }

  .navbar-collapse {
    flex-basis: 100% !important;

    ul {
      li {
        list-style-type: none !important;
      }
    }
  }
}
</style>