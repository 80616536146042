export default {
    banner: {
        title: 'Haverfordwest Public Transport Interchange',
        text: [
            'Pembrokeshire County Council have been working alongside a team of consultants to undertake the design of a modern transport interchange to replace the former multi-storey car park and bus station in Haverfordwest. ',
            'The initial detailed design was completed by AtkinsRéalis (Atkins) in March 2023 and demolition works were completed on site in April 2023. ',
            'Following a successful tender exercise in November 2023, Kier Construction Western & Wales were appointed to undertake an exercise of refinement to reduce the overall cost of scheme to provide value for money, whilst maintaining an improved interchange experience and sustainable public transport options for users.'

        ],
        button: 'Find out more',
        brandLogo: 'https://firebasestorage.googleapis.com/v0/b/haverfordwest-60687.appspot.com/o/Branding%2Flogo_EN_cropped.png?alt=media&token=cd5d888c-2870-4b1b-a78c-1ce50f1dc78a',
        company: 'https://firebasestorage.googleapis.com/v0/b/haverfordwest-60687.appspot.com/o/Branding%2FAtkinsRealis.png?alt=media&token=b8c95f5e-39c9-43df-8a66-56aa8c5753b9',
        company2: 'https://firebasestorage.googleapis.com/v0/b/haverfordwest-60687.appspot.com/o/Branding%2FWorking%20obo%20WG_white.png?alt=media&token=c616031c-cff0-48f8-9faf-dc47fbc7d646',
        navs: {
            pages: [
                /*                { nav: "Project Purpose", route: "projectPurpose", order: 1 },
                                { nav: "Documents", route: "readmore", order: 2 },
                                { nav: "Current plans", route: "currentplan", order: 3 },
                                { nav: "Construction", route: "construction", order: 4 },
                                { nav: "Contact", route: "feedback", order: 5 }*/
            ]
        }
    },
    section1: {
        title: 'Project Purpose ',
        text: [
            'The interchange is a fundamental part of the wider regeneration plans within Haverfordwest and demonstrate ' +
            'the authorities’ approach for regeneration in accordance with Welsh Government’s \'Transit Orientated Development’.' +
            ' Transport is a key blocker to development in many areas, and the provision of a high quality, modern facility ' +
            'ensures the attractiveness of Haverfordwest, coupled with wider regeneration to provide trip generators ' +
            'and attractions with other schemes in the Town centre such as the signature bridge and Western quayside works.',
            'Please take a look at a virtual flythrough of the new interchange at the link below.',
        ],
        button: 'Watch video',
        buttonLink: 'https://vimeo.com/859401033?share=copy'
    },
    section2: {
        title: 'Current plans ',
        text: [
            `Detailed design of the interchange has been completed by AtkinsRéalis (Atkins) with the input of Pembrokeshire County Council and local residents.`,
            `Construction is expected to commence late in Autumn '24 following a period of design refinement undertaken by appointed construction contractor, Kier Construction Western & Wales.`,
            `The scheme is being funded from the Welsh Government's Local Transport Fund, with match funding from Pembrokeshire County Council.`,
            `During construction, Kier Construction Western & Wales have committed to providing a number of Community Benefit and Social Value initiatives which will be communicated as we near the construction phase.`,
            `Further updates will be provided here as the scheme progresses to construction.`
        ]
    },
    section3: {
        title: `What’s happening now?`,
        imageLink: 'https://firebasestorage.googleapis.com/v0/b/haverfordwest-60687.appspot.com/o/BackgroundImages%2FTimelineX_eng.png?alt=media&token=f8f9cb9d-3c62-4c10-8d6d-809885d19af0',
        imageAltText: '',
        text: [
            `The construction phase is now programmed to commence in Autumn 2024 following a period of design refinement with completion due early Spring 2026.`,
            `Once construction commences, the current temporary parking will be removed which will be communicated to members of the public in advance.`,
            `Cartlett Road will remain in operation during the works, under a single lane closure where necessary, while Riverside shopping centre will remain open and operational throughout the construction.`

        ]
    },
    section4: {
        title: "Construction Documents & Plans",
        text:
            'If you are unable to access the documents electronically you may request copies of this information by emailing',
        emailid: 'hwpti@atkinsglobal.com',
        buttonTitle: "Show all",
        tiles:
        {
            Boxes: [
                {
                    no: 1,
                    TileName: "Air quality assessment",
                    linkhref: "https://firebasestorage.googleapis.com/v0/b/haverfordwest-60687.appspot.com/o/Documents%2FAir%20Quality%20assessment_5201245%20.pdf?alt=media&token=0e309d6b-43fa-4773-9083-ad29f76e1ca9",
                    fileType: "Document",
                    imagesrc: "https://firebasestorage.googleapis.com/v0/b/haverfordwest-60687.appspot.com/o/baseline-arrow_forward-24px_green.png?alt=media&token=d88b3b40-6bf1-47ca-95d2-d6ed01bde82d"
                },
                {
                    no: 2,
                    TileName: "Architecture drawings",
                    linkhref: "https://firebasestorage.googleapis.com/v0/b/haverfordwest-60687.appspot.com/o/Documents%2FArchitecture%20Website%20Compilaiton.pdf?alt=media&token=ba92b862-6f2d-4bf8-8b66-fed78b4296af",
                    fileType: "Drawings",
                    imagesrc: "https://firebasestorage.googleapis.com/v0/b/haverfordwest-60687.appspot.com/o/baseline-arrow_forward-24px_green.png?alt=media&token=d88b3b40-6bf1-47ca-95d2-d6ed01bde82d"
                },
                {
                    no: 3,
                    TileName: "Construction renders",
                    linkhref: "https://firebasestorage.googleapis.com/v0/b/haverfordwest-60687.appspot.com/o/Documents%2FConstructionRenders%203.0.pdf?alt=media&token=407c8895-9023-4e1d-a1f5-2d42bb4b5a42",
                    fileType: "Images",
                    imagesrc: "https://firebasestorage.googleapis.com/v0/b/haverfordwest-60687.appspot.com/o/baseline-arrow_forward-24px_green.png?alt=media&token=d88b3b40-6bf1-47ca-95d2-d6ed01bde82d"
                },
                {
                    no: 4,
                    TileName: "Demolition photos",
                    linkhref: "https://firebasestorage.googleapis.com/v0/b/haverfordwest-60687.appspot.com/o/Documents%2FDemolitionPhotos.pdf?alt=media&token=cf63f1e5-4865-4d20-a0ac-311841532315",
                    fileType: "Images",
                    imagesrc: "https://firebasestorage.googleapis.com/v0/b/haverfordwest-60687.appspot.com/o/baseline-arrow_forward-24px_green.png?alt=media&token=d88b3b40-6bf1-47ca-95d2-d6ed01bde82d"
                },
                {
                    no: 5,
                    TileName: "Drainage drawings",
                    linkhref: "https://firebasestorage.googleapis.com/v0/b/haverfordwest-60687.appspot.com/o/Documents%2FDrainage%20Website%20Compilation.pdf?alt=media&token=58376848-12b5-44ac-a413-976f100bc538",
                    fileType: "Drawings",
                    imagesrc: "https://firebasestorage.googleapis.com/v0/b/haverfordwest-60687.appspot.com/o/baseline-arrow_forward-24px_green.png?alt=media&token=d88b3b40-6bf1-47ca-95d2-d6ed01bde82d"
                },
                {
                    no: 6,
                    TileName: "Flood consequences assessment",
                    linkhref: "https://firebasestorage.googleapis.com/v0/b/haverfordwest-60687.appspot.com/o/Documents%2FFlood%20consequences%20assessment_CS101371_FCA_v0.1.pdf?alt=media&token=629f99cf-cff2-47f7-8b83-fb0af13eae47",
                    fileType: "Document",
                    imagesrc: "https://firebasestorage.googleapis.com/v0/b/haverfordwest-60687.appspot.com/o/baseline-arrow_forward-24px_green.png?alt=media&token=d88b3b40-6bf1-47ca-95d2-d6ed01bde82d"
                },
                {
                    no: 7,
                    TileName: "Heritage impact statement",
                    linkhref: "https://firebasestorage.googleapis.com/v0/b/haverfordwest-60687.appspot.com/o/Documents%2FHeritage%20Impact%20Statement_PE09-ATK-EHR-SWMWREC_BS-RP-LH-000002.pdf?alt=media&token=f27cb960-b728-400b-8ca8-6e22f6b1382d",
                    fileType: "Document",
                    imagesrc: "https://firebasestorage.googleapis.com/v0/b/haverfordwest-60687.appspot.com/o/baseline-arrow_forward-24px_green.png?alt=media&token=d88b3b40-6bf1-47ca-95d2-d6ed01bde82d"
                },
                {
                    no: 8,
                    TileName: "Highways drawings",
                    linkhref: "https://firebasestorage.googleapis.com/v0/b/haverfordwest-60687.appspot.com/o/Documents%2FHPTI%20Website%20Highways%20Compilation.pdf?alt=media&token=ccb2c369-ca92-477d-b09b-01b7dfeaf1a9",
                    fileType: "Drawings",
                    imagesrc: "https://firebasestorage.googleapis.com/v0/b/haverfordwest-60687.appspot.com/o/baseline-arrow_forward-24px_green.png?alt=media&token=d88b3b40-6bf1-47ca-95d2-d6ed01bde82d"
                },
                {
                    no: 9,
                    TileName: "Landscape drawings",
                    linkhref: "https://firebasestorage.googleapis.com/v0/b/haverfordwest-60687.appspot.com/o/Documents%2FLandscape%20Website%20compilation%202.pdf?alt=media&token=94f6b74b-8f58-44b3-9c4f-77324d55a248",
                    fileType: "Drawings",
                    imagesrc: "https://firebasestorage.googleapis.com/v0/b/haverfordwest-60687.appspot.com/o/baseline-arrow_forward-24px_green.png?alt=media&token=d88b3b40-6bf1-47ca-95d2-d6ed01bde82d"
                },
                {
                    no: 10,
                    TileName: "Planning, Design And Access Statement",
                    linkhref: "https://firebasestorage.googleapis.com/v0/b/haverfordwest-60687.appspot.com/o/Documents%2FPDAS_PE09-ATK-LDC-SWMWREC_BS-RP-CX-000003_PDAS.pdf?alt=media&token=1e726c5c-798e-44ec-ac14-33235348c896",
                    fileType: "Document",
                    imagesrc: "https://firebasestorage.googleapis.com/v0/b/haverfordwest-60687.appspot.com/o/baseline-arrow_forward-24px_green.png?alt=media&token=d88b3b40-6bf1-47ca-95d2-d6ed01bde82d"
                },
                {
                    no: 11,
                    TileName: "Structures",
                    linkhref: "https://firebasestorage.googleapis.com/v0/b/haverfordwest-60687.appspot.com/o/Documents%2FStructures%20Website%20Compilation.pdf?alt=media&token=061d3bdd-537d-4721-a6ce-bd666e801941",
                    fileType: "Drawings",
                    imagesrc: "https://firebasestorage.googleapis.com/v0/b/haverfordwest-60687.appspot.com/o/baseline-arrow_forward-24px_green.png?alt=media&token=d88b3b40-6bf1-47ca-95d2-d6ed01bde82d"
                },
                {
                    no: 12,
                    TileName: "Transport assessment",
                    linkhref: "https://firebasestorage.googleapis.com/v0/b/haverfordwest-60687.appspot.com/o/Documents%2FTransportAssessment_PE09-ATK-HTA-SWMWREC-RP-TR-000001_TA.pdf?alt=media&token=5dfe6f29-ee7f-40f3-b98b-0c420ef8b087",
                    fileType: "Document",
                    imagesrc: "https://firebasestorage.googleapis.com/v0/b/haverfordwest-60687.appspot.com/o/baseline-arrow_forward-24px_green.png?alt=media&token=d88b3b40-6bf1-47ca-95d2-d6ed01bde82d"
                },
            ]
        },
    },
    feedback: {
        title: 'Need to contact the team?',
        text: [
            'Please fill out the form below to submit your enquiry to the team or alternatively please write to:',
        ],
        textAddress: [
            'Haverfordwest Public Transport Interchange, AtkinsRéalis,',
            '12 Orchard St,',
            'Swansea, West Glamorgan',
            'SA1 5AD',
        ],
        commentBoxes: [
            { optionDetail: "Enquiry", placeholder: 'Please enter your enquiry' }
        ],
        dataProtection: [
            'Comments will be recorded for discussion and consideration of the management of the scheme and works. ' +
            'By ticking this box, you agree for this information to be recorded. The privacy and security of this ' +
            'data is a priority to Atkins and will be used solely for use in relation to the Haverfordwest Public ' +
            'Transport Interchange project. If you wish to understand how we handle this data you may refer to our' +
            ' privacy notice. \n',
        ],
        submitBtn: 'Submit',
        nameAddress: {
            fname: {
                label: 'First name',
                placeholder: 'Please enter your first name'
            },
            lname: {
                label: 'Last name',
                placeholder: 'Please enter your last name'
            },
            number: {
                label: 'Phone number',
                placeholder: 'Please enter your phone number'
            },
            email: {
                label: 'Email address',
                placeholder: 'Please enter your email address'
            },
            address1: {
                label: 'First line of address',
                placeholder: 'Please enter the first line of your address'
            },
            address2: {
                label: 'Second line of address',
                placeholder: 'Please enter the second line of your address'
            },
            city: {
                label: 'City',
                placeholder: 'Please enter the city or town you reside'
            },
            postCode: {
                label: 'Post code',
                placeholder: 'Please enter your post code'
            },
            subject: {
                label: "Subject",
                placeholder: 'Please enter your subject'
            },
            message: {
                label: "Enquiry",
                placeholder: 'Please enter your enquiry'
            },

        }
    },
    footer: {
        brandLogo: 'https://firebasestorage.googleapis.com/v0/b/newgale-landingpage.appspot.com/o/Pembrokeshire%20county%20council.png?alt=media&token=28bfc637-792d-42b3-a0d9-8011797f6a1c',
        footerText: '© 2023 Copyright Pembrokeshire County Council',
        links: 'Follow us',
        policy: "View our privacy policy"
    }
}